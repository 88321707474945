var Cameraland = Cameraland || {};

Cameraland.ScrollTo = class {
    constructor(options) {
        this.scrollTo = this.scrollTo.bind(this);

        this.options = $j.extend({}, {
            scrollBtnSelector: '.scroll-to'
        }, options);

        this.scrollTo();
    }

    scrollTo() {
        const scrollButton = $j(this.options.scrollBtnSelector);

        scrollButton.on('click', (event) => {
            event.preventDefault();

            const currentTarget = $j(event.currentTarget);

            const scrollToSelector = currentTarget.attr('href') || currentTarget.attr('data-scroll-to');
            const scrollOffset = currentTarget.attr('data-scroll-offset') || 70;


            $j('html, body').animate({
                scrollTop: $j(scrollToSelector).offset().top - scrollOffset
            }, 300);
            return true;
        });
    }
};

$j(function () {
    new Cameraland.ScrollTo();
});